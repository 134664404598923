@import "../../colors";

.Dashboard {
  position: relative;

  .main-container {
    display: flex;
    flex-direction: row;

    .nav-container {
      width: 200px;
      position: relative;
      z-index: 10;
      background-color: #fff;
      min-height: 100vh;
      z-index: 11;

      .nav-inner {
        position: fixed;
        height: 100vh;
        overflow-y: auto;
        width: 200px;
        left: 0;
        top: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        background-color: $primaryColor;
        transition: transform 0.2s;

        .logo-container {
          text-align: center;
          height: 64px;

          .logo {
            height: 32px;
            margin-top: 19px;
            filter: brightness(0) invert(1);
          }

          .close {
            display: none;
          }
        }
      }
    }
  
    .content-container {
      margin-top: 65px;
      padding-bottom: 70px;
      width: calc(100% - 200px);
  
      .content-inner {
        width: 100%;
      }
    }
  
    @media (max-width: 900px) {
      .content-container {
        width: 100%;
      }

      .nav-container {
        width: 0;

        .nav-inner {

          &.closed {
            transform: translateX(-201px);
          }

          .logo-container {
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 64px;
  
            .logo {
              height: 32px;
              margin-top: 7px;
              margin-bottom: -3px;
            }

            .close {
              display: flex;
              font-size: 27px;
              cursor: pointer;
              margin-right: 21px;
              margin-left: 20px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
