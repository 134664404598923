$primaryColor: #04223e;
$primaryColorLight: #ebf6ff;
$primaryColorMedium: #5cabd3;
$secondaryColor: #ff7755;
$successColor: #56a529;
$alternateColor: #feaf48;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
$grayBackground: #d5d9dc;
