@import "../../../colors";

.Loading {

  .main-loading-container {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000000;

    .main-loading-container-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      text-align: center;
      width: 100%;

      .main-loading-bars-container {

        .loading-bars {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }

        .loading-bars div {
          display: inline-block;
          position: absolute;
          left: 8px;
          width: 16px;
          animation: loading-bars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

          &:first-of-type {
            background: $secondaryColor;
          }

          &:nth-of-type(2) {
            background-color: $alternateColor;
            background-image: linear-gradient(to right, $secondaryColor, $alternateColor);
          }

          &:last-of-type {
            background: $alternateColor;
          }
        }

        .loading-bars div:nth-child(1) {
          left: 8px;
          animation-delay: -0.24s;
        }

        .loading-bars div:nth-child(2) {
          left: 32px;
          animation-delay: -0.12s;
        }

        .loading-bars div:nth-child(3) {
          left: 56px;
          animation-delay: 0;
        }

        @keyframes loading-bars {
          0% {
            top: 8px;
            height: 64px;
          }
          50%, 100% {
            top: 24px;
            height: 32px;
          }
        }
      }
    }
  }
}
