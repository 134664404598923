@import "../../../colors";

.ResetPassword {
  position: relative;

  .form-container {
    max-width: 600px;
    padding: 70px 20px;
    margin: auto;

    .auth-well {
      background-color: #fff;

      .logo-container {
        text-align: center;

        .logo {
          height: 50px;
        }
      }

      h1 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0 20px;
      }

      .forgot-password-message {
        text-align: center;
        color: #333;
        font-size: 14px;
        margin: 30px 0 20px;
      }

      button {
        width: 100%;
        margin-top: 20px;
      }
    }

    a {
      font-size: 12px;
      color: $linkColor;
      text-decoration: underline;
    }

    .sign-in-container {
      font-size: 12px;
      text-align: center;
      padding: 12px 0;
      color: #777777;
    }
  }
}
